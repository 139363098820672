<template>
  <v-row>
    <v-col cols="12">
      <v-alert v-model="showFormAlert" type="error">{{ alertText }}</v-alert>
      <signature-pad v-model="formData.signature"></signature-pad>
    </v-col>
    <v-col class="text-center mt-2">
      <v-btn class="primary" :loading="loadingSubmit" large depressed @click.stop="submit">
        Guardar firma
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    signaturePad: () => import("@/components/signature-pad"),
  },
  data: () => ({
    alertText: "Tienes que firmar antes de enviar el formulario.",
    formData: { signature: null },
    showFormAlert: null,
    loadingSubmit: null,
  }),
  methods: {
    async submit() {
      this.showFormAlert = false;

      if (!this.formData.signature) {
        this.showFormAlert = true;
      } else {
        try {
          this.loadingSubmit = true;
          await this.$store.state.currentService.uploadTrainerSignature(this.formData);
          await this.$store.dispatch("handleTrainerSignature", true);
        } catch (e) {
          this.$notifyError(undefined, "Error al guardar la firma | " + e);
        } finally {
          this.loadingSubmit = false;
        }
      }
    },
  },
};
</script>

<style scoped></style>
